import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'

// import hcOffcanvasNav from 'hc-offcanvas-nav';

// import Mmenu from 'mmenu-js';


$( document ).on('turbolinks:load', function() {    
    lightGallery(document.getElementById('photo_list'), {
        plugins: [lgThumbnail],
        licenseKey: '756500E0-BB9342A7-A033F1CE-036B7797',
        download: false,
        speed: 500
    });
    
  
  // if ($("#my_menu").length !== 0) {
  //   new Mmenu( "#my_menu", {wrappers: ["turbolinks"]});
  // }
  
  // var Nav = new hcOffcanvasNav('#my_menu', {
  //   disableAt: false,
  //   customToggle: '.toggle',
  //   navTitle: 'Menu',
  //   levelTitles: true,
  //   levelTitleAsBack: true
  // });
    
 
});
