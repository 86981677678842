import { DateTime } from "luxon";
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import { Dropzone } from "dropzone";

var trashArray = [];
function addToTrashArray(photo_id) {
    // console.log("Adding " + photo_id + " To trash array");
    trashArray.push(photo_id);
    enableTrashButton()
}

function removeFromTrashArray(photo_id) {
    var index = trashArray.findIndex(e => e === photo_id)
    if (index >= 0) {
        trashArray.splice(index, 1);
        // console.log("Removing " + photo_id  + "  from trash array");
    }
    // console.log("New trash array length is " + trashArray.length);
    if (trashArray.length === 0) {
        console.log(" removeFromTrashArray : disabling button");
        disableTrashButton();
    }
}

function enableTrashButton() {
    // console.log($("#trash_button").prop('disabled'));
    
    if ($("#trash_button").prop('disabled') === true) {
        // console.log("Button is disabled, enabling now");
        $("#trash_button").prop('disabled', false);
        
        $("#trash_button").on('click', function() {
            console.log("Settnig click handler");
            // console.log("There are " + trashArray.length + " items to delete");
        });                
    }
    
    // $("#trash_button").prop('disabled', false);
    // console.log("Enabling click");

    
}

function disableTrashButton() {
    $("#trash_button").off('click');
    $("#trash_button").prop('disabled', true);
}


$( document ).on('turbolinks:load', function() {
    

    
    if ($('#dropbox_container').length) {
        
        let dropbox_options = {

            // Required. Called when a user selects an item in the Chooser.
            success: function(files) {
        		var list = '';
        		var count = 0;
        		var links = [];
				
        		    jQuery.each( files, function( i, val ) {
        				links.push(encodeURIComponent(val.link));
        		    let sale_id = $("#dropbox_container").data("id");
        			var jqxhr = $.ajax({type: "POST",
        							   url: "/admin/photos/queue_photos", 
        							   data: { upload_paths: encodeURIComponent(val.link), sale_id: sale_id }})
        			  .done(function() {
        				$("#list").html("Added photos to upload queue");
        				// update_progress();					
        			  })
        			  .fail(function(jqXHR, textStatus, errorThrown) {
        			    alert( "error : " + errorThrown );
        			  });					
				
        			});
        			// update_progress();
            },

            // Optional. Called when the user closes the dialog without selecting a file
            // and does not include any parameters.
            cancel: function() {
            },

            // Optional. "preview" (default) is a preview link to the document for sharing,
            // "direct" is an expiring link to download the contents of the file. For more
            // information about link types, see Link types below.
            linkType: "direct", // or "direct"

            // Optional. A value of false (default) limits selection to a single file, while
            // true enables multiple file selection.
            multiselect: true, // or true

            // Optional. This is a list of file extensions. If specified, the user will
            // only be able to select files with these extensions. You may also specify
            // file types, such as "video" or "images" in the list. For more information,
            // see File types below. By default, all extensions are allowed.
            extensions: ['.jpg', '.jpeg']
        };        
        
        var button = Dropbox.createChooseButton(dropbox_options);
        $("#dropbox_container").append(button);
    }

    $( "#photo_delete_dialog" ).dialog({
          resizable: false,
          height:160,
          modal: true,
          autoOpen: false,
          buttons: {
            "Delete  photo": function() {
                let photo_id = $("#photo_delete_dialog").data("id");

                $.post( "/admin/estate_sales/delete_photo?photo_id="+photo_id, function( data ) {
                    let sortable = document.getElementById('sale_photos_sortable');
                    let childNode = document.getElementById('sortable_photo_' + photo_id);                
                    sortable.removeChild(childNode);
                });
                
                $( this ).dialog( "close" );
            },
            Cancel: function() {
              $( this ).dialog( "close" );
            }
          }
        });
    
    
    
    
	 $('.edit_estate_sale').validate({
		 ignore: ".date",
		 rules: {			 
			 'estate_sale[address]': "required",
			 'estate_sale[city]': "required",
			 'estate_sale[state]': "required",
			 'estate_sale[postal_code]': "required",
			 'estate_sale[headline]': "required",
			 'estate_sale[description]': "required"			 
		 }
	 });
     
 	flatpickr(".start_date", {
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y h:i K",
        dateFormat: "Z",
        defaultHour: 9
 	});
 	$( ".end_date" ).flatpickr({
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y h:i K",
        dateFormat: "Z",
        defaultHour: 17
 	});
	
    flatpickr("#estate_sale_expiration_date", {        
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y h:i K",
        dateFormat: "Z"
    });
	
 	$("#estate_sale_display_date").flatpickr({
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y h:i K",
        dateFormat: "Z"
 	});
	
 	$("#mailing_scheduled_send_time").flatpickr({
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y h:i K",
        dateFormat: "Z"
 	});     
     
	

    var el = document.getElementById('sale_photos_sortable');
    if (el) {
    	var sortable = Sortable.create(el, {
            multiDrag: true,
            selectedClass: "sortable-selected",
            multiDragKey: 'SHIFT',
            filter: '.delete_photo_icon',
            group: {
              name: 'photolist',
                pull: 'clone'
            },
            onSelect: function(/**Event*/evt) {
                // console.log("Selecting");
                // console.log(" item id " + evt.item.id);
                // addToTrashArray(evt.item.id);
            	evt.item // The selected item
                
            },
        	// Called when an item is deselected
        	onDeselect: function(/**Event*/evt) {
                // console.log("Deselecting " + evt.item.id);
        		evt.item // The deselected item
                // removeFromTrashArray(evt.item.id)
        	},
            onUpdate: function(evt) {
                var photo_array = sortable.toArray();
                $.post( "sort_photos/", {"photos[]": photo_array }, function( data ) {});
            },
            onFilter: function (evt) {
              var ctrl = evt.target;              
              
              let photo_id = $(evt.item).data('id');
              if (Sortable.utils.is(ctrl, '.delete_photo_icon')) {
                $( "#photo_delete_dialog" ).data("id", photo_id).dialog("open");
                
                // sale_photos_sortable.removeChild(sortable_photo_)
                // evt.from.removeChild(evt.item);
              }
            }
        });
    }
    
    
    // $("#trash_button").click(function() {
    //     console.log("Settnig click handler");
    //     // console.log("There are " + trashArray.length + " items to delete");
    // });
    
    
    // $(".delete_photo_icon").on('click', function() {
    //     $( "#photo_delete_dialog" ).data("id", $(this).data('id')).dialog("open");
    // });
    
    $("#trash_button").on('click', function() {
        // console.log("Settnig click handler");
        // console.log("There are " + trashArray.length + " items to delete");
    });
    
    var phiconel = document.getElementById('photo_icon_droppable');
    if (phiconel) {
    	var icon_sortable = Sortable.create(phiconel, {
            sort: false,
            group: {
              name: 'photoicon',
              put: ['photolist']
            },
            animation: 100,
            onAdd: function(evt) {
                $("#icon_image").hide();
                
                var photo_id = $(evt.item).data("id");
                $.post( "/admin/estate_sales/photo_icon?photo_id="+photo_id+"&estate_sale_id="+$("#sale_expire_sale").data('sale-id'), function( data ) {
                    location.reload();                    
                });                
            }
        });        
    }
    


    
    
    var currentSalesEl = document.getElementById('admin_current_sales');
    if (currentSalesEl) {
    	var currentSalesSortable = new Sortable(currentSalesEl, {
            ghostClass: "blue-background-class",
            onEnd: function(evt) {                
                $.post( "/admin/estate_sales/sort_current_sales", {sales: currentSalesSortable.toArray()}, function( data ) {});
            }
        });
    }
    
	
	$("#admin_send_test_mail").click(function() {		
        $.post( "/admin/mailing/send_test", {subject: $("#mailing_subject").val(), mailing_test_addresses: $("#mailing_test_addresses").val()}, function() {
        })
		$("#response_message").html("Sent Test Message")
	    return true;
	});	
	
	$("#admin_send_mail").click(function() {
        $.post( "/admin/mailing", {subject: $("#mailing_subject").val(), scheduled_send_time: $("#mailing_scheduled_send_time").val()}, function() {
        })
		$("#response_message").html("Preparing Mass Mailing")
	    return true;
	});	
		
	$("#sale_expire_sale").click(function() {
		$.post( "/admin/estate_sales/"+$("#sale_expire_sale").data('sale-id')+"/expire_sale", function() {});
        
		var today = new Date();
		var yesterday = new Date(today);
		yesterday.setDate(today.getDate() - 1);

	    var dd = yesterday.getDate();
	    var mm = yesterday.getMonth()+1; //January is 0!

	    var yyyy = yesterday.getFullYear();
	    if(dd<10){dd='0'+dd} 
	    if(mm<10){mm='0'+mm} 
	    yesterday = yyyy+'-'+mm+'-'+dd;  		
		
		$("#estate_sale_expiration_date").val(yesterday);
		$("#response_message").html("Expired Sale")
		
	    return true;
	});	
	
	
    $("#sale_delete_photos").click(function() {
        $( "#dialog-confirm" ).dialog("open");

        return true;
    });


    $( "#dialog-confirm" ).dialog({
          resizable: false,
          height:160,
          modal: true,
          autoOpen: false,
          buttons: {
            "Delete all photos": function() {

                  $.ajax({
                      url: "/admin/estate_sales/"+$("#sale_delete_photos").data('sale-id')+"/delete_photos",
                      type: 'DELETE',
                      success: function(result) {
                          // console.log("Sending delete message");
                          // Do something with the result
                      }
                  });
                $( this ).dialog( "close" );
            },
            Cancel: function() {
              $( this ).dialog( "close" );
            }
          }
        });
	
	
	$(".beacon_delete").click(function(e) {
		var $link = $(e.currentTarget);
        var hiddenField = $link.prev('input[type=hidden]');
		
		var beacon_id = hiddenField.attr('id').replace("beacon_destroy_", "")
        hiddenField.val(beacon_id);

        var field = $link.closest('li');
        field.hide();
	    return true;
	});		

    if ($('#newsphotoform').length) {
        const dropzone = new Dropzone("form#newsphotoform", {headers: {'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')} });
    
        dropzone.on("complete", function(file) {
            location.reload();
        });
    }
});

$(document).on('cocoon:after-insert',  function(e, insertedItem, originalEvent) {
    // var nearestEnd = insertedItem.closest('.nested-fields').find('.flatpickr_input');
    // var nearestEnd = insertedItem.prev('.nested-fields');
    // insertedItem.css( "background-color", "red" )
    
    
    var nearestEnd = insertedItem.prevAll('.nested-fields:first');
    // nearestEnd.css( "background-color", "red" );

    var datetimepicker = nearestEnd.find('.flatpickr-input');
    // console.log(datetimepicker.val());
    // Set start and end times based on previous entry
    var startDate = DateTime.fromISO(datetimepicker.val()).plus({days: 1});
    
    // it's a jQuery object already! Now you can find date input
    var dateField = insertedItem.find('.start_date');
    dateField.flatpickr({
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y h:i K",
        defaultDate: startDate.set({hour: 10}).toString(),
        dateFormat: "Z"
    });
    
     var dateField = insertedItem.find('.end_date');
     dateField.flatpickr({
         enableTime: true,
         altInput: true,
         altFormat: "F j, Y h:i K",
         defaultDate: startDate.set({hour: 17}).toString(),
         dateFormat: "Z"
     });
  
});
