/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("@rails/ujs").start()
require("turbolinks").start()

require("jquery")
// require("jquery-ui-dist")
import "../src/jquery-ui"

import { Dropzone } from "dropzone";

import { DateTime } from "luxon";
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

// require("flatpickr")
import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css");

import "jquery-validation/dist/jquery.validate"

require("@nathanvda/cocoon")

import lightGallery from 'lightgallery';
import("lightgallery/css/lightgallery-bundle.css");
import lgThumbnail from 'lightgallery/plugins/thumbnail'

import bulmaCollapsible from '@creativebulma/bulma-collapsible';

// import Mmenu from 'mmenu-js';
// import("mmenu-js/dist/mmenu.css");

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
library.add(fas)
dom.watch()

import '../src/jquery.mmenu.min';
import '../src/admin.js';
import '../src/sales.js';

// import 'styles/menu';
// import '../src/sale_beacons.js';
// import '../src/blueimp_gallery/blueimp-gallery.js';
// import '../src/blueimp_gallery/jquery.blueimp-gallery.js';

document.addEventListener("turbolinks:load", () => {
    // console.log('turbolinks:load event fired');
    bulmaCollapsible.attach();
    // flatpickr("[class='flatpickr']", {})
})

// document.addEventListener('DOMContentLoaded', function() {
//     console.log("DOMContentLoaded event listener fired");
//   flatpickr("[class='flatpickr']");
// })


// $(function () {
//   console.log('Hello World from Webpacker');
//     // flatpickr("[class='flatpickr']", {});
// });